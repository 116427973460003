import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { StyledBase } from '@twilio-paste/theme';
import { Text } from '@twilio-paste/text';
import Changelog from '@twilio-paste/tooltip-primitive/CHANGELOG.md';
import { useTooltipPrimitiveState, TooltipPrimitive, TooltipPrimitiveReference, TooltipPrimitiveArrow } from '@twilio-paste/tooltip-primitive';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { defaultExample, rightExample, styledExample } from '../../../component-examples/TooltipPrimitiveExamples';
export const pageQuery = graphql`
  {
    allPastePrimitive(filter: {name: {eq: "@twilio-paste/tooltip-primitive"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/primitives/tooltip-primitive/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Tooltip Primitive" categoryRoute={SidebarCategoryRoutes.PRIMITIVES} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/primitives/tooltip" storybookUrl="/?path=/story/primitives-tooltip--simple-tooltip" data={props.data.allPastePrimitive.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Tooltip Primitive`}</h3>
        <p>{`The tooltip primitive is an unstyled functional version of a tooltip. It can be used to build a component following the `}<a parentName="p" {...{
            "href": "https://www.w3.org/TR/wai-aria-practices/#tooltip"
          }}>{`WAI-ARIA Tooltip`}</a>{`
pattern. Our `}<a parentName="p" {...{
            "href": "/components/tooltip"
          }}>{`Tooltip`}</a>{` is built on top of this primitive.`}</p>
        <p>{`The purpose of providing these unstyled primitives is to cater for instances when the styled Tooltip provided by Paste doesn’t meet the requirements needed to solve a unique or individual customer problem.
At that point you are welcome to fall back to this functional primitive to roll your own styled tooltip while still providing a functional and accessible experience to your customers.
However, we strongly recommend reviewing your design proposal with the Design Systems team before doing so.`}</p>
        <p>{`This primitive should be used to compose all custom tooltips to ensure accessibility and upgrade paths.`}</p>
        <Callout variant="warning" mdxType="Callout">
  <CalloutTitle as="h3" mdxType="CalloutTitle">Before you roll your own tooltip...</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    We strongly suggest that all components built on top of this primitive get reviewed by the Design Systems team and
    go through the UX Review process to ensure an excellent experience for our customers.
  </CalloutText>
        </Callout>
        <h2>{`Examples`}</h2>
        <h3>{`Basic Tooltip`}</h3>
        <LivePreview scope={{
          useTooltipPrimitiveState,
          TooltipPrimitive,
          TooltipPrimitiveReference,
          Button
        }} noInline mdxType="LivePreview">
  {defaultExample}
        </LivePreview>
        <h3>{`Controlling placement`}</h3>
        <p>{`The placement of the tooltip is configurable via the placement prop.
The available placement options are available in the `}<a parentName="p" {...{
            "href": "#usetooltipprimitivestate"
          }}>{`props description here`}</a>{`.`}</p>
        <LivePreview scope={{
          useTooltipPrimitiveState,
          TooltipPrimitive,
          TooltipPrimitiveReference,
          Button
        }} noInline mdxType="LivePreview">
  {rightExample}
        </LivePreview>
        <hr></hr>
        <h2>{`Styling a Custom Tooltip`}</h2>
        <p>{`The Tooltip primitive can be styled using Paste components and tokens. By using the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop, we can
change the underlying element and combine it with another component. In the example below we're combining
the `}<inlineCode parentName="p">{`TooltipPrimitiveReference`}</inlineCode>{` with the Button component. Inside the `}<inlineCode parentName="p">{`TooltipPrimitive`}</inlineCode>{` we're using the
Box primitive to create a container with Paste token background color, border radius, and spacing values.
Because the tooltip lives outside of the main `}<inlineCode parentName="p">{`body`}</inlineCode>{` tag, we need to provide the base Paste styles using
`}<inlineCode parentName="p">{`StyledBase`}</inlineCode>{` from our theme package. This gives the `}<inlineCode parentName="p">{`Text`}</inlineCode>{` primitive the appropriate font stack and sizing.
This example also incorporates the `}<inlineCode parentName="p">{`TooltipPrimitiveArrow`}</inlineCode>{` to give the Tooltip a more polished look.`}</p>
        <LivePreview scope={{
          useTooltipPrimitiveState,
          TooltipPrimitive,
          TooltipPrimitiveReference,
          TooltipPrimitiveArrow,
          Box,
          Button,
          StyledBase,
          Text
        }} noInline mdxType="LivePreview">
  {styledExample}
        </LivePreview>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <p>{`This package is a wrapper around the `}<a parentName="p" {...{
            "href": "https://reakit.io/docs/tooltip/"
          }}>{`Reakit Tooltip`}</a>{`. If you’re wondering why we wrapped that package into our own, we reasoned that it would be best for our consumers’ developer experience. For example:`}</p>
        <ul>
          <li parentName="ul">{`If we want to migrate the underlying nuts and bolts in the future, Twilio products that depend on this primitive would need to replace all occurrences of `}<inlineCode parentName="li">{`import … from ‘x-package’`}</inlineCode>{` to `}<inlineCode parentName="li">{`import … from ‘@some-new/package’`}</inlineCode>{`. By wrapping it in `}<inlineCode parentName="li">{`@twilio-paste/x-primitive`}</inlineCode>{`, this refactor can be avoided. The only change would be a version bump in the ‘package.json\` file for the primitive.`}</li>
          <li parentName="ul">{`We can more strictly enforce semver and backwards compatibility than some of our dependencies.`}</li>
          <li parentName="ul">{`We can control when to provide an update and which versions we allow, to help reduce potential bugs our consumers may face.`}</li>
          <li parentName="ul">{`We can control which APIs we expose. For example, we may chose to enable or disable usage of certain undocumented APIs.`}</li>
        </ul>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/tooltip-primitive - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Props`}</h4>
        <p>{`This props list is a scoped version of the properties available from the `}<a parentName="p" {...{
            "href": "https://reakit.io/docs/tooltip/"
          }}>{`Reakit Tooltip`}</a>{` package.`}</p>
        <h5>{`useTooltipPrimitiveState`}</h5>
        <h5><inlineCode parentName="h5">{`baseId?: string`}</inlineCode></h5>
        <p>{`Sets the ID that will serve as a base for all the items IDs.`}</p>
        <h5><inlineCode parentName="h5">{`gutter?: string`}</inlineCode></h5>
        <p>{`Sets the offset between the reference and the tooltip on the main axis.`}</p>
        <h5><inlineCode parentName="h5">{`placement?: "auto-start" | "auto" | "auto-end" | "top-start...`}</inlineCode></h5>
        <p>{`Sets the placement of popover in relation to the `}<inlineCode parentName="p">{`TooltipPrimitiveReference`}</inlineCode>{`. Available options include:`}</p>
        <ul>
          <li parentName="ul">{`auto-start`}</li>
          <li parentName="ul">{`auto-end`}</li>
          <li parentName="ul">{`auto`}</li>
          <li parentName="ul">{`top-start`}</li>
          <li parentName="ul">{`top-end`}</li>
          <li parentName="ul">{`top`}</li>
          <li parentName="ul">{`bottom-start`}</li>
          <li parentName="ul">{`bottom-end`}</li>
          <li parentName="ul">{`bottom`}</li>
          <li parentName="ul">{`right-start`}</li>
          <li parentName="ul">{`right-end`}</li>
          <li parentName="ul">{`right`}</li>
          <li parentName="ul">{`left-start`}</li>
          <li parentName="ul">{`left-end`}</li>
          <li parentName="ul">{`left`}</li>
        </ul>
        <h5><inlineCode parentName="h5">{`visible?: boolean`}</inlineCode></h5>
        <p>{`Whether the tooltip is visible or not.`}</p>
        <h5><inlineCode parentName="h5">{`animated?: number | boolean`}</inlineCode></h5>
        <p>{`If true, animating will be set to true when visible is updated. It'll wait for stopAnimation to be called or a CSS transition ends. If animated is set to a number, stopAnimation will be called only after the same number of milliseconds have passed.`}</p>
        <h5>{`TooltipPrimitiveArrow`}</h5>
        <h5><inlineCode parentName="h5">{`size?: string | number | undefined`}</inlineCode></h5>
        <p>{`The size of the arrow`}</p>
        <h5><inlineCode parentName="h5">{`fill?: string`}</inlineCode></h5>
        <p>{`Sets the fill color of the arrow svg path`}</p>
        <h5><inlineCode parentName="h5">{`stroke?: string`}</inlineCode></h5>
        <p>{`Sets the stroke color of the arrow svg path`}</p>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      